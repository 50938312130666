/**
 * Vue Router
 *
 * @library
 *
 * https://router.vuejs.org/en/
 */

// Lib imports
import _ from 'lodash'
import Vue from 'vue'
//import VueAnalytics from 'vue-analytics'
import Router from 'vue-router'
import Meta from 'vue-meta'
import appStore from '../store'
import route from '../misc/route'
import { APP_MUTATIONS, AUTH_MUTATIONS } from '../store/mutations'
import { APP_ACTIONS, AUTH_ACTIONS } from '../store/actions'
import { hasAnyPermission, getAccessToken, getRefreshToken, removeToken } from '../misc/auth'
import { paths } from './paths'
import { USER_TYPE } from './../misc/commons'
import Amplify, * as AmplifyModules from 'aws-amplify';
import { AmplifyPlugin } from 'aws-amplify-vue';
import AmplifyStore from '../store/store';
Vue.use(Router)
Vue.use(AmplifyPlugin, AmplifyModules);

// let user;
// getUser().then((user, error) => {
//   if (user) {
//     //router.push({path: '/'})
//   }
// })

// function getUser() {
//   debugger
//   return Vue.prototype.$Amplify.auth.currentAuthenticatedUser().then((data) => {
//     if (data && data.signInUserSession) {
//       AmplifyStore.commit('setUser', data);
//       return data;
//     } 
//   }).catch((e) => {
//     AmplifyStore.commit('setUser', null);
//     return null
//   });
// }
// Create a new router
let vueRouter = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: getDefaultRoute()
    },
    ...route.buildConfig(paths),
    {
      path: '**',
      redirect: paths.notFound.path
    }
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    if (to.hash) {
      return { selector: to.hash }
    }
    return { x: 0, y: 0 }
  }
})

// let IS_LOADED
const LOGIN_PATH = paths.login.path
const NOTFOUND_PATH = paths.recalls.path

export function getDefaultRoute() {
  let path = ''
  //debugger
  switch (appStore.state.userType) {
    case USER_TYPE.NOTI:
      path = paths.recalls.path
      break
    case USER_TYPE.PROV:
      path = paths.recalls.path
      break
    case USER_TYPE.SUPP:
      path = paths.recalls.path
      break
    default:
      //debugger
      // if (!getAccessToken() && !getRefreshToken()) {
      //   path = paths.recalls.path
      // }
      // else  {
        path = paths.login.path
        
      //}
      
      break
  }
  return path
}

const checkPermission = (to, next) => {
  //debugger
  if (appStore.state.token) {
    if (to.path === LOGIN_PATH) {
      const Atoken = getAccessToken()
      const Rtoken = getRefreshToken()
      if (Atoken == null && Rtoken == null) {    
      next({
        path:
          appStore.state && appStore.state.lastedRouteNotAuth ? appStore.state.lastedRouteNotAuth.path : getDefaultRoute()
          //'/recalls'
      })
    }
    else  {
      next({
        path:
          //appStore.state && appStore.state.lastedRouteNotAuth ? appStore.state.lastedRouteNotAuth.path : getDefaultRoute()
          '/recalls'
      })
    }
    }
    if (to.meta && to.meta.permissions && to.meta.permissions.length > 0) {
      if (!hasAnyPermission(to.meta.permissions)) {
        next(getDefaultRoute())
      // next(NOTFOUND_PATH)
      } else {
        next()
      }
    } else {
      next()
    }
  } else {
    let redirect = appStore.state.lastedRouteNotAuth || vueRouter.currentRoute
    if (to.meta.auth == false) {
      next()
    } else if (to.path !== LOGIN_PATH) {
      vueRouter.push({
        path: LOGIN_PATH,
        query: {
          redirect: redirect.fullPath || redirect.path
        }
      })
    } else {
      next()
    }
  }
}

vueRouter.beforeEach((to, from, next) => {
  appStore.commit(APP_MUTATIONS.LOADING_START)
  //debugger
  const Atoken = getAccessToken()
  const Rtoken = getRefreshToken()
  if (Atoken == null && Rtoken == null) {    
    removeToken()
    if (to.meta.auth) {
      vueRouter.push({
        path: LOGIN_PATH
      })
    } else {
      if (to.matched.length && to.path != '/') {
        //this.$router.push({name: 'Recalls'})
        next()
      } else
      if (!to.matched.length || to.path == '/') {
        vueRouter.push({
          path: LOGIN_PATH
        })
      }
    }
  } else {
    //debugger
    // vueRouter.push({
    //   path: NOTFOUND_PATH
    // })
    //this.$router.push({name: 'Recalls'})
    //next(getDefaultRoute())
    // vueRouter.push({
    //   path: NOTFOUND_PATH
    // })
    // next()
    // next({
    //   path:
    //   paths.recalls.path
    // })

    //if (!to.matched.length) {
      //this.$router.push({name: 'Recalls'})
      //next()
      //next(getDefaultRoute())
    //} else
    //this.$router.push({name: 'Recalls'})
      checkPermission(to, next)
    
  }  
})

vueRouter.afterEach((to, from) => {
  appStore.commit(APP_MUTATIONS.LOADING_END)
  appStore.commit(APP_MUTATIONS.CHANGED_ROUTER, to)
  appStore.dispatch(APP_ACTIONS.SET_PREVIOUS_ROUTE, from)
})

vueRouter.onReady(() => {
  appStore.subscribe((mutations, store) => {
    switch (mutations.type) {
      case AUTH_MUTATIONS.AUTH_SUCCESS: {
        let redirect = store.lastedRouteNotAuth || vueRouter.currentRoute //usually, this is login path
        if (!redirect.meta.auth) {
          let redirectTo = ''
          if (!_.isEmpty(redirect.query)) {
            if (redirect.query.redirect) {
              if (redirect.query.redirect === '/') {
                redirectTo = getDefaultRoute()
              } else {
                redirectTo = redirect.query.redirect
              }
            } else {
              redirectTo = getDefaultRoute()
            }
          } else {
            redirectTo = getDefaultRoute()
          }
          vueRouter.push(redirectTo).catch(err => {})
        }
        break
      }
      case AUTH_MUTATIONS.AUTH_ERROR: {
        let redirect = store.lastedRouteNotAuth || vueRouter.currentRoute
        if (redirect.meta.auth && redirect.path !== LOGIN_PATH) {
          if (['NotFound'].includes(redirect.name))
            vueRouter.push({
              path: LOGIN_PATH
            })
          else
            vueRouter.push({
              path: LOGIN_PATH,
              query: {
                redirect: redirect.fullPath || redirect.path
              }
            })
        } else if (vueRouter.currentRoute.path !== LOGIN_PATH){
            vueRouter.push({
              path: LOGIN_PATH
            })
        }
        break
      }
    }
  })
  appStore.dispatch(AUTH_ACTIONS.GET_USER_PROFILE).then(() => {
    vueRouter.beforeEach((to, from, next) => {
      next()
    })
    appStore.commit(APP_MUTATIONS.INIT_APP_FINISHED)
  })
})

Vue.use(Meta)

// Bootstrap Analytics
// Set in .env
// https://github.com/MatteoGabriele/vue-analytics
// if (process.env.GOOGLE_ANALYTICS) {
//   Vue.use(VueAnalytics, {
//     id: process.env.GOOGLE_ANALYTICS,
//     vueRouter,
//     autoTracking: {
//       page: process.env.NODE_ENV !== 'development'
//     }
//   })
// }

export default vueRouter
