import { setToken, removeToken, getPermissions, getUserType, getOrgId } from '../misc/auth'
import i18n from '../i18n'

export const APP_MUTATIONS = {
  AUTH_REQUEST: 'AUTH_REQUEST',
  CHANGED_ROUTER: 'CHANGED_ROUTER',
  INIT_APP_FINISHED: 'INIT_APP_FINISHED',
  LASTED_ROUTE_NOT_AUTH: 'LASTED_ROUTE_NOT_AUTH',
  SET_PREVIOUS_ROUTE: 'SET_PREVIOUS_ROUTE',
  FORCE_LOGIN: 'FORCE_LOGIN',
  LOADING_START: 'LOADING_START',
  LOADING_END: 'LOADING_END',
}

export const AUTH_MUTATIONS = {
  AUTH_SUCCESS: 'AUTH_SUCCESS',
  INIT_APP_FINISHED: 'INIT_APP_FINISHED',
  //INIT_APP_FINISHED
  UPDATE_USER_PROFILE: 'UPDATE_USER_PROFILE',
  AUTH_ERROR: 'AUTH_ERROR',
  SELECT_TYPE_LOGIN: 'SELECT_TYPE_LOGIN',
  UPDATE_ORGANIZATION_INFO: 'UPDATE_ORGANIZATION_INFO',
}

export const LANG_MUTATIONS = {
  SET_LANG: 'SET_LANG'
}

const appMutations = {
  [APP_MUTATIONS.AUTH_REQUEST]: (state) => {},
  [APP_MUTATIONS.CHANGED_ROUTER]: (state, current) => {
    state.currentRoute = current
  },
  [APP_MUTATIONS.INIT_APP_FINISHED]: state => {
    state.inited = true
  },
  [APP_MUTATIONS.LASTED_ROUTE_NOT_AUTH]: (state, currentRoute) => {
    state.lastedRouteNotAuth = currentRoute
  },
  [APP_MUTATIONS.SET_PREVIOUS_ROUTE]: (state, path) => {
    state.previousRoute = path
  },
  [APP_MUTATIONS.FORCE_LOGIN]: (state) => {},
  [APP_MUTATIONS.LOADING_START]: (state) => {
    state.loading = true
  },
  [APP_MUTATIONS.LOADING_END]: (state) => {
    state.loading = false
  }
}

const authMutations = {
  [AUTH_MUTATIONS.AUTH_SUCCESS]: (state, token) => {
    setToken(token)
    state.token = token
    state.isAuth = true    
    //debugger
    //state.permissions = getPermissions()
    //state.userType = 'NOTISPHERE'
    //state.orgId = getOrgId()
    //state.lastedRouteNotAuth = 
    //state.lastedRouteNotAuth = currentRoute
  },
  [AUTH_MUTATIONS.UPDATE_USER_PROFILE] (state, user) {
    state.user = user
    state.isAuth = true
  },
  [AUTH_MUTATIONS.UPDATE_ORGANIZATION_INFO] (state, organization) {
    state.organization = organization
  },
  [AUTH_MUTATIONS.SELECT_TYPE_LOGIN] (state, data) {
    state.selectUserTypes = data
  },
  [AUTH_MUTATIONS.AUTH_ERROR]: (state) => {
    removeToken()
    state.user = ''
    state.isAuth = false
    state.token = null
    state.organization = null
  }
}

const langMutations = {
  [LANG_MUTATIONS.SET_LANG]: (state, payload) => {
    i18n.locale = payload
  }
}

export default {
  ...appMutations,
  ...authMutations,
  ...langMutations
}