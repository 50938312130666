import VueBootstrap4Table from 'vue-bootstrap4-table'

export default {
    components: {
        VueBootstrap4Table
    },
    props: {
        //name for the table
        tableName: { type: String, default: '' },
        //data for the table 
        header: { type: Array, default: [], required: true },
        items: { type: Array, default: [], required: true },
        total: { type: Number, default: 0, required: true },
        //label for add more button
        addMoreLabel: { type: String, default: 'Add' },
        //show or hide the add more button
        showAddMore: { type: Boolean, default: false },
        showSearchBtn: { type: Boolean, default: false },
        showSearch: { type: Boolean, default: false },
        //hide global search
        showGlobalSearch: { type: Boolean, default: false },
        showD: { type: Boolean, default: false },
        showV: { type: Boolean, default: false },
        showI: { type: Boolean, default: false },
        showNC: { type: Boolean, default: false},
        customPagination: { Type: Boolean, default: false }
        
    },
    data() {
        return {
            perPage: 10,
            perPageEmit: 10,
            currentPage: 1,
            per_page: [10, 20, 30, 40, 50],
            showSearch_: false,
            globalSearch: '',
            theTableKey: 0,
            config: {
                card_mode: false,
                highlight_row_hover_color: '#efefef',
                global_search: {
                    visibility: false,
                    showClearButton: false
                },
                show_refresh_button: false,
                show_reset_button: false,

                pagination: false,
                pagination_info: false,
                num_of_visibile_pagination_buttons: 5,
                per_page: 10,
                per_page_options: [10, 20, 30, 40, 50],
                server_mode: true,
                multi_column_sort: false,
                rows_selectable: true,
                selected_rows_info: false,
            },
            queryParams: {
                per_page: 10,
                page: 1,
            },
            classes: {
                table: 'table-hover'
            }
        }
    },
    created() {
        if (this.customPagination == true) {
            this.perPageEmit = 50,
            this.perPage=10
        }
    },
    mounted() {
        this.showSearch_ = this.showSearch
    },
    watch: {
        perPageEmit(val) {
            this.$emit('update:searchQuery', {
                pageNumber: this.currentPage,
                pageSize: val,
                sort: this.queryParams.sort,
                search: this.globalSearch
            })
            this.requestFetchData()
        },
        currentPage(val) {
            this.$emit('update:searchQuery', {
                pageNumber: val,
                pageSize: this.perPageEmit,
                sort: this.queryParams.sort,
                search: this.globalSearch
            })
            this.requestFetchData()
        },
        queryParams: {
            deep: true,
            handler(val) {
                this.$emit('update:searchQuery', {
                    pageNumber: this.currentPage,
                    pageSize: this.perPageEmit,
                    sort: val.sort,
                    search: this.globalSearch
                })
            }
        },
        globalSearch(val) {
            this.$emit('update:searchQuery', {
                pageNumber: this.currentPage,
                pageSize: this.perPageEmit,
                sort: this.queryParams.sort,
                search: val
            })
        }
    },
    computed: {
        slotNameList() {
            var arr = []
            this.header.forEach(el => {
                if (el.name) {
                    arr.push(el.name.replace('.', '_'))
                }
            })
            return arr
        }
    },
    methods: {
        selectPerPage(perPage) {
            this.perPage = perPage
            this.perPageEmit = perPage
        },
        rowClick(payload) {
            this.$emit('rowClick', payload.selected_item)
        },
        rowUnClick(payload) {
            this.$emit('rowClick', payload.unselected_item)
        },
        fetchDelay() {
            if (this.timer) {
                clearTimeout(this.timer)
                this.timer = null
            }
            this.timer = setTimeout(() => {
                if (this.queryParams.page == 1) {
                    this.requestFetchData()
                } else {
                    this.theTableKey += 1
                }
            }, 500)
            debugger
        },
        onChangeQuery(queryParams) {
            this.queryParams = queryParams
            this.requestFetchData()
        },
        requestFetchData() {
            //error on server mode ( when not in page 1 and make filter, it will emit twice the request) => set timeout
            if (this.timer2) {
                clearTimeout(this.timer2)
                this.timer2 = null
            }
            this.timer2 = setTimeout(() => {
                this.$emit('requestFetchData')
            }, 5)
        },
        addMoreClick() {
            this.$emit('addMoreClick')
        },
        addDClick() {
            this.$emit('addDClick')
        },
        addVerifyClick() {
            this.$emit('addVerifyClick')
        },
        addInvalidClick() {
            this.$emit('addInvalidClick')
        },
        addCoveredClick() {
            this.$emit('addCoveredClick')
        },
        DisableClick() {
            this.$emit('DisableClick')
        }
    }
}