<template>
  <div id="app">
    <component :is="layout"/>
    <core-loading />
    <core-notify ref="notify" />
    <core-toast ref="toast" />
    <core-confirm ref="confirm" />
  </div>
</template>

<style lang="scss"></style>

<script>
  export default {
    computed: {
      layout() {
        return this.$route.meta.layout || 'layout-default'
        //return 'layout-default'
      }
    },
    mounted() {
      window.vm = this.$refs.notify || null
      window.vmToast = this.$refs.toast || null
    },
    watch: {
      '$route' (to, from) {
        document.title = to.meta.title || 'Notisphere ER'
      }
    }
  }
</script>
